import React from "react";

// Local imports
import config from "data/SiteConfig";
import styles from "./Footer.module.scss";

const Footer = () => (
	<footer className="container m-t-xl m-b-xl">
		<div className="">
			<div>
				<a
					href={`https://twitter.com/${config.userTwitter}`}
					target="_blank"
					rel="noopener noreferrer"
					className="m-r-m"
				>
					Twitter
				</a>
				<a
					href={`https://github.com/${config.userGitHub}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					GitHub
				</a>
			</div>
			<div className={styles.copyright}>{config.copyright}</div>
		</div>
	</footer>
);

export default Footer;
