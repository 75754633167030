import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image";

// Local imports
import config from "data/SiteConfig";
import Categories from "src/components/generic/Categories/Categories";
import { ABOUT_PAGE, CONTACT_ME_PAGE, LEARN_PAGE } from "src/routesConstants";
import styles from "./Header.module.scss";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toggleMenu: false
		};
	}

	toggleMenu = () => {
		this.setState(prevState => ({
			toggleMenu: !prevState.toggleMenu
		}));
	};

	render() {
		const isMenuActive = this.state.toggleMenu ? "is-active" : "";

		return (
			<nav
				className="navbar container"
				role="navigation"
				aria-label="main navigation"
			>
				<div className={`navbar-brand ${isMenuActive}`}>
					<Link to="/">
						<Img
							className={styles.overrideFluidSize}
							fluid={
								this.props.data.fileName.childImageSharp.fluid
							}
							alt=""
							fadeIn={false}
						/>
					</Link>
					<a
						role="button"
						className="navbar-burger"
						aria-label="menu"
						aria-expanded="false"
						onClick={this.toggleMenu}
						tabIndex={0}
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>
				<div className={`navbar-menu ${isMenuActive}`}>
					<div className="navbar-end">
						<div className="navbar-start">
							<Link
								className="navbar-item"
								to={"/data-for-seo/"}
								activeClassName={styles.activeNav}
							>
								Data for SEO
							</Link>
							<Link
								className="navbar-item"
								to={"/training/"}
								activeClassName={styles.activeNav}
							>
								Training
							</Link>
							<Link
								className="navbar-item"
								to={LEARN_PAGE}
								activeClassName={styles.activeNav}
							>
								Learn
							</Link>
							<div className="navbar-item">
								<span className={styles.theSquare} />
							</div>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}

export default () => (
	<StaticQuery
		query={graphql`
			query {
				fileName: file(
					relativePath: { eq: "images/logos/main_logo.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 800, maxHeight: 200) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={data => <Header data={data} />}
	/>
);

// eslint-disable-next-line react/prefer-stateless-function
// class Header extends React.Component {
// 	render() {
// 		console.log(this.props);

// 		return (

// 		);
// 	}
// }

// export const query = graphql`
// 	query {
// 		file(relativePath: { regex: "/a/" }) {
// 			id
// 		}
// 	}
// `;

// export default Header;
