export const ABOUT_PAGE = "/about/";
export const CONTACT_ME_PAGE = "/contact/";
export const LEARN_PAGE = "/learn/";

export const userJourneyMapping = {
	learn_seo: "/journeys/learn-seo/",
	tools: "/journeys/tools/",
	working_with_data: "/journeys/working-with-data/",
	work_skills: "/journeys/work-skills/",
	web_dev: "/journeys/web-dev/",
	web_basics: "/journeys/web-basics/"
};

export const allPosts = "/all/";
